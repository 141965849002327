import SheduledPage from 'pages/scheduled-page/ScheduledPage';

export const privateRoutes = [
  {
    path: '/viewb-register/:rpaId',
    isPrivate: true,
    template: '',
    component: SheduledPage,
    subRoutes: [],
  },
  {
    path: '/viewb-conciliation/:rpaId',
    isPrivate: true,
    template: '',
    component: SheduledPage,
    subRoutes: [],
  },
  {
    path: '/viewb-cancellation/:rpaId',
    isPrivate: true,
    template: '',
    component: SheduledPage,
    subRoutes: [],
  },
  {
    path: '/elev-bitrix-omie/:rpaId',
    isPrivate: true,
    template: '',
    component: SheduledPage,
    subRoutes: [],
  },
  {
    path: '/bamko-any-omie/:rpaId',
    isPrivate: true,
    template: '',
    component: SheduledPage,
    subRoutes: [],
  },
  //
];
